.grow {
  transform: scale(1.5);
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.image-hover {
  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }
}

.small-shadow {
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.4));
}

.discount-hover {
  &:hover {
    @extend .bg-card-linear-hover;
  }
}
