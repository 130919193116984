.inspirations_form {
  .settingsInput__wrapper {
    position: relative;

    textarea {
      width: 100%;
    }

    .MuiInputAdornment-root {
      position: absolute;
      right: 5%;
    }
  }

  &_textarea {
    padding: 8px;
    gap: 10px;
    height: 250px;
    border: 1px solid #b5b5b5;
    border-radius: 10px;
  }

  .submit_button {
    padding: 16px 24px;
    gap: 8px;

    width: 50%;
    height: 50px;

    background: #2496cc;
    border-radius: 40px;

    flex: none;
    order: 3;
    flex-grow: 0;
    font-size: 16px;

    letter-spacing: 0.05em;

    color: #ffffff;

    svg {
      width: 15px;
      height: 15px;
      font-size: 20px;
    }
  }

  .submit_button:hover {
    opacity: 0.9;
  }

  .submit_button:disabled {
    cursor: not-allowed;
  }
}
