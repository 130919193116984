.profile {
  &__coverImage {
    height: 350px;
    width: 100%;
    position: relative;
    display: flex;

    @media (max-width: 599px) {
      display: none;
    }

    &--text {
      position: absolute;
      top: 225px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-align: right;

      h1 {
        margin-right: 60px;
      }
    }
  }

  &__add-banner {
    align-items: center;
    padding: 10px 25px;
    gap: 10px;
    position: absolute;
    height: 40px;
    background: #4d4e4e;
    border-radius: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    label {
      display: flex;
      cursor: pointer;
      column-gap: 12px;
    }
  }

  &__contact {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;

    @media (min-width: 599px) {
      min-width: 419px;
      max-width: 419px;
      padding: 16px;
    }

    &--avatar {
      height: 130px;
      width: 130px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      margin: 0 auto;
    }

    &--username {
      margin-top: 16px;
    }

    &--bottom {
    }
  }
}
