.chat {
  width: 100%;
  padding-top: 56px;

  &__upper {
    background-color: #363636;
    height: 273px;

    &--content {
      max-width: 1200px;
      padding: 24px;
      margin: 0 auto;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.025em;
      color: #2496cc;
      max-width: 590px;
    }
  }

  &__main {
    max-width: 1200px;
    padding: 32px 24px;
    margin: 0 auto;

    &--header {
      display: grid;
      row-gap: 8px;

      h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.025em;
        color: #4d4e4e;
      }

      h6 {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.025em;
        color: #4d4e4e;
      }
    }

    &--channels {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-top: 24px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    gap: 24px;
    margin-top: 28px;
    border-top: 1px solid #b5b5b5;
    padding-top: 8px;

    &--left {
      display: flex;
      gap: 18px;
      border-right: 1px solid #b5b5b5;
      padding-right: 16px;

      label {
        display: grid;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      input {
        display: none;
      }
    }

    &--input {
      input {
        width: 100%;
        border: none;
        outline: none;

        &::placeholder {
          @include cabin;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #4d4e4e;
        }
      }
    }

    &--button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      gap: 8px;
      width: 119px;
      height: 62px;
      background: #2496cc;
      border-radius: 40px;

      @include cabin;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }
  }
}

.messages {
  display: grid;
  row-gap: 32px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 0 10px 0;

  .right {
    justify-self: right;
  }

  .left {
    justify-self: left;
  }
}

.message {
  max-width: 599px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 25px;

    &--left {
      display: flex;
      column-gap: 16px;
      align-items: center;

      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.025em;
      }
    }

    &--right {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.025em;
      color: #b5b5b5;
    }
  }

  &__main {
    background: #ffffff;
    box-shadow: 0px 2px 10px #26334d11;
    border-radius: 10px;
    padding: 30px;

    p {
      @include cabin;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4d4e4e;
    }
  }
}

.message-file {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(38, 51, 77, 0.162);
  border-radius: 10px;
  display: grid;
  justify-content: center;
  gap: 17px;
  padding: 10px;
  min-width: 300px;

  &__main {
    text-align: center;
  }

  span {
    @include cabin;
    color: #2496cc;
  }

  h5 {
    @include cabin;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2496cc;
    cursor: pointer;
  }
}
