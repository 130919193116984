.sidenav {
  @extend .p-fixed;
  @extend .depth-l-2;
  @extend .gap-normal;
  @extend .overflow-x-hidden;
  @extend .overflow-y-auto;
  @extend .h-full;
  @extend .hide-scrollbar;
  width: 0;
  top: 0;
  left: 0;
  transition: 0.4s;
}

#maps {
  transition: margin-left 0.4s;
}
