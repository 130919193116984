.footer {
  display: grid;
  row-gap: 70px;

  @media (max-width: $screen-md-min) {
    justify-content: center;
    row-gap: 25px;
  }

  &__primaryText {
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 30px;

    @media (max-width: $screen-sm-min) {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  &__upper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $screen-md-min) {
      display: grid;
      justify-content: center;
      row-gap: 25px;
    }
  }

  &__left {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 60px;
    row-gap: 25px;

    @media (max-width: $screen-md-min) {
      grid-template-columns: repeat(2, max-content);
      grid-template-rows: repeat(2, max-content);
    }
  }

  &__siteMap {
    @media (max-width: $screen-sm-min) {
      grid-row: 1/2;
      grid-column: 1/2;
    }
  }

  &__languages {
    @media (max-width: $screen-sm-min) {
      grid-row: 1/2;
      grid-column: 2/3;
    }
  }

  &__listBox {
    &--main {
      display: grid;
      row-gap: 10px;

      h6 {
        @include cabin;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.025em;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &__newsletter {
    text-align: center;
    @media (max-width: $screen-md-min) {
      grid-row: 2/3;
      grid-column: 1/3;
    }

    &--labelText {
      @include cabin;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.025em;
      color: #ffffff;
    }

    &--labelText > a {
      color: #ffffff;
      text-decoration: underline;
    }

    &--inputBox {
      display: grid;
      grid-template-columns: 1fr 140px;
      width: 100%;
      height: 50px;
      overflow: hidden;

      @media (max-width: $screen-sm-min) {
        height: 38px;
      }

      input {
        outline: none;
        background-color: unset;
        color: #ffffff;
        border: 1px solid #ffffff;
        padding: 0 8px;
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        &::placeholder {
          color: #ffffff;
        }
      }
    }

    &--subscribe {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: #ffffff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      cursor: pointer;

      h6 {
        color: #2496cc;
        @include cabin;
        line-height: 17px;
        font-weight: 500;
        letter-spacing: 0.05em;
      }
    }
  }

  &__social {
    text-align: right;
    @media (max-width: $screen-md-min) {
      text-align: center;
      display: grid;
      justify-content: center;
    }
  }

  &__bottom {
    display: grid;
    align-items: center;
    justify-content: center;

    &--upper {
      display: flex;
      column-gap: 5px;

      @media (max-width: $screen-md-min) {
        display: grid;
        justify-content: center;
      }

      h6,
      a {
        font-weight: 700;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: -0.025em;
        color: #ffffff;
        text-align: center;

        @media (max-width: $screen-md-min) {
          font-size: 10px;
        }
      }
    }

    &--poweredBy {
      font-weight: 700;
      font-size: 10px;
      line-height: 26px;
      letter-spacing: -0.025em;
      text-align: center;

      span {
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}

.bottomInfo {
  padding: 16px 0px;
  background: #b5b5b51a;
  width: 100%;
  align-self: flex-end;

  &__main {
    max-width: 1248px;
    padding: 0 24px;
    display: grid;
    row-gap: 16px;
    margin: 0 auto;

    p {
      @include cabin;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.025em;

      color: #828282;
    }
  }

  &__header {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.025em;
    color: #828282;
  }
}
