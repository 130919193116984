.pricing {
  &__main {
    display: grid;
    row-gap: 32px;
  }

  &__primaryText {
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.025em;
    color: #4d4e4e;
    text-align: center;
  }

  &__primaryTextAlign {
    text-align: left !important;
    @media (max-width: 768px) {
      text-align: center !important;
    }
  }

  &__primarySubText {
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.025em;

    color: #2496cc;
  }

  &__secondaryText {
    @include cabin;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4d4e4e;
  }

  &_block-details {
    margin: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
    }

    &-descr_wrap {
      width: 100%;
    }

    &-title {
      margin-right: 38px;
    }

    &-text {
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.025em;
      color: #2496cc;
      margin-right: 8px;
      cursor: pointer;
    }

    &-items {
      margin-top: 48px;
      display: flex;
      flex-wrap: wrap;
      gap: 44px 10px;
      margin-bottom: 46px;

      &_item {
        width: 268px;
        min-height: 378px;
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0);
        transition: 0.2s all;
        letter-spacing: -0.025em;

        &-active {
          border: 1px solid #2496cc;
          transition: 0.2s all;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
          border: 1px solid #2496cc;
          transition: 0.2s all;
        }

        /* primary/lighten2 */

        font-family: "Montserrat";
        font-style: normal;
        color: #4d4e4e;

        &-title {
          margin-bottom: 17px;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
        }

        &-subtitle {
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          margin-top: 16px;
        }

        &-bonus {
          font-weight: 700;
          font-size: 16px;
          color: #2496cc;
          margin-top: 16px;
        }

        hr {
          display: inline-block;
          width: 30px;
          height: 0px;
          border: 1px solid #b5b5b5;
        }

        &-price {
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
          letter-spacing: -0.025em;
          text-align: left;
        }

        &-btn {
          margin-top: 16px;
          font-family: "Cabin";
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.05em;
          color: #2496cc;

          background-color: #fff;
          padding: 8px 25px;
          min-width: 117px;
          height: 50px;
          border: 1px solid #2496cc;
          border-radius: 100px;
          transition: all 0.3s;

          &-active {
            background: #2496cc;
            color: #ffffff;
            transition: all 0.3s;

            svg {
              margin-left: 22px;
            }
          }

          &:hover {
            background: #2496cc;
            color: #ffffff;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  // PRICING CARD
  // Overall
  &__card {
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 0px 0px;

    .divider-h {
      width: 30px;
      height: 1px;
      background-color: #b5b5b5;
      margin: 0 auto;
    }

    .divider-v {
      width: 1px;
      height: 30px;
      background-color: #b5b5b5;
    }

    &--circle {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 214px;
      height: 56px;
      border: 1px solid #2496cc;
      border-radius: 100px;
      cursor: pointer;
      margin: 0 auto;
      transition: all 0.2s ease-out;

      &:active {
        background-color: #2496cc;

        h4 {
          color: #ffffff !important;
        }
      }

      &:hover {
        h4 {
          color: #2496cc;
        }
      }

      h4 {
        transition: all 0.2s ease-out;
        letter-spacing: unset;
        color: #4d4e4e;
      }
    }

    &--details {
      max-width: 479px;
      margin: 0 33px;

      p {
        @include cabin;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #4d4e4e;
      }
    }

    &--box {
      display: grid;
      row-gap: 16px;
      text-align: center;
      width: 308px;

      &_pricings {
        width: 26%;

        .pricing {
          &__primaryText {
            margin-right: 16px;
          }

          &__btn {
            padding: 8px 25px;
            border-radius: 100px;
            height: 56px;
            margin-top: 20px;

            &:hover,
            &:active {
              background-color: #2496cc;
              color: #ffffff;
              transition: all 0.4s;
            }
          }
        }

        .buy {
          .MuiFormControl-root {
            width: 100%;
            text-align: start;
            margin: 0;
            margin-bottom: 15px;
          }

          button {
            width: auto;
            border: 1px solid #2496cc;
            color: #4d4e4e;
            background: #ffffff;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
            width: 60%;
          }
        }

        .soon h2 {
          font-weight: 700;
          font-size: 28px;
          line-height: 28px;
          text-align: center;
          letter-spacing: -0.025em;
          color: #f4bf00 !important;
        }
      }
    }

    // When the pricing__card is selected
    .selected {
      background: #2496cc !important;
      color: #ffffff;

      span {
        align-self: center;
      }

      .pricing__card--priceBox {
        grid-template-columns: repeat(4, max-content);

        h6 {
          color: #ffffff;
        }

        .period {
          margin-right: 16px;
        }

        .price {
          color: #ffffff;
        }
      }
    }

    // When the pricing__card (yearly) is selected
    .yearly {
      grid-template-columns: repeat(2, max-content);
      grid-template-rows: repeat(2, max-content);
      column-gap: 16px;

      .discount {
        grid-row: 1/2;
        grid-column: 1/2;
      }

      span {
        grid-row: 1/3;
        grid-column: 2/3;
      }

      .pricing__card--priceBox {
        grid-template-columns: repeat(3, max-content);
        grid-row: 2/3;
        grid-column: 1/2;

        .period {
          margin-right: 0;
        }
      }
    }

    // When the pricing__card is active
    .active {
      padding: 3.5px 0;
      border: 1px solid #828282;

      &:hover {
        .pricing__card--priceBox {
          color: #4d4e4ea7;

          .price {
            color: #4d4e4ea7;
          }
        }
      }

      .pricing__card--priceBox {
        color: #4d4e4ea7;

        .price {
          color: #4d4e4ea7;
        }
      }

      h3 {
        font-weight: 600;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.025em;
        color: #2496cc;
      }
    }

    &--select {
      width: 231px;
      height: 68px;
      display: grid;
      justify-content: center;
      align-items: center;
      row-gap: 8px;
      border: 1px solid #2496cc;
      border-radius: 100px;
      padding: 8px 0;
      color: #4d4e4e;
      cursor: pointer;

      .discount {
        transition: all 0.2s ease-out;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.025em;
      }

      &:hover {
        .discount {
          color: #2496cc;
        }

        .pricing__card--priceBox {
          color: #2496cc;
        }
      }
    }

    &--priceBox {
      height: 28px;
      display: grid;
      grid-template-columns: repeat(3, max-content);
      margin: 0 auto;
      color: #4d4e4e;
      transition: all 0.2s ease-out;

      .currency {
        margin-right: 2px;
        align-self: flex-start;
      }

      .period {
        align-self: flex-end;
      }

      h6 {
        @include cabin;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.015em;
      }

      .price {
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        letter-spacing: -0.025em;
      }
    }
  }

  // Desktop
  &__cardD {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 16px 24px;
    // column-gap: 20px;
  }

  &__cardDExpanded {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px;
  }

  // Mobile
  &__cardM {
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.253);
    border-radius: 10px;

    // This is when pricing__cardM--main is opened
    .opened {
      height: auto;
      transition: all 1s ease-out;
    }

    &--main {
      padding: 24px 0 16px;
      display: grid;
      row-gap: 16px;
      justify-content: center;
      height: 281px;
      overflow: hidden;
      transition: all 1s ease-out;

      .pricing__card--box {
        margin: 0 auto;
      }
    }

    &--header {
      text-align: center;
      display: grid;
      gap: 8px;
    }

    &--bottom {
      cursor: pointer;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #b5b5b5;
    }
  }

  &__stepper {
    margin-top: 32px;
  }

  // Pricing Basket
  &__basket {
    &--header {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.025em;
      color: #363636;
    }

    &--main {
      display: grid;
      row-gap: 16px;
      padding: 32px 0;
      margin: 32px 0 28px;
      border-bottom: 1px solid #b5b5b5;
      border-top: 1px solid #b5b5b5;
    }

    &--item {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, max-content);
      justify-content: space-between;

      @media (max-width: 599px) {
        grid-template-columns: unset;
        justify-content: center;
        row-gap: 8px;
      }

      .pricing__card--priceBox {
        min-width: 150px;
        text-align: center;
        justify-content: center;
      }
    }

    &--bottom {
      display: grid;
      justify-content: flex-end;
      row-gap: 28px;
    }
  }
}

@media (max-width: $screen-md-min) {
  .pricing {
    &__cardD {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 16px 24px;
      // column-gap: 20px;
    }

    &__primaryText {
      // margin: 0 !important;
    }

    &_block-details-items {
      justify-content: center;
    }

    &__btn {
      width: 30%;
    }

    &__card {
      .divider-v {
        display: none;
      }

      &--details {
        margin: 33px 0;
      }

      &--box {
        width: 100%;
        text-align: center;

        .soon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
