$dark-theme: false;

$light: (
  primary-lighten3: #2496cc4d,
  primary-lighten2: #2496cc,
  primary-lighten1: #2084b2,
  primary: #1b7098,
  primary-darken1: #175e80,
  primary-darken2: #124b66,
  secondary-lighten2: #fcc97c,
  secondary-lighten1: #fab552,
  secondary: #f9a11b,
  secondary-darken1: #e38a02,
  secondary-darken2: #cc7c02,
  success-lighten2: #92e38b,
  success-lighten1: #64db5a,
  success: #3bc030,
  success-darken1: #2b8c23,
  success-darken2: #0a4f04,
  warning-lighten2: #ffe588,
  warning-lighten1: #ffd12e,
  warning: #f4bf00,
  warning-darken1: #c89c00,
  warning-darken2: #866800,
  error-lighten2: #ffa490,
  error-lighten1: #ff5a36,
  error: #df3814,
  error-darken1: #b52100,
  error-darken2: #721400,
  background: #dde2eb,
  surface: #ffffff,
  text-lighten2: #b5b5b5,
  text-lighten1: #828282,
  text: #4f4f4f,
  text-darken1: #363636,
  text-darken2: #1c1c1c,
  disabled: #cccccc,
  transparent: transparent,
  background-linear:
    linear-gradient(180deg, rgba(190, 190, 190, 0) 0%, #bebebe 100%),
  card-linear: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, #000000 90%),
  card-linear-hover: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 90%),
  header-linear: linear-gradient(90deg, rgba(36, 150, 204, 0) 0%, #2496cc 100%),
  sm-header-linear:
    linear-gradient(0deg, rgba(36, 150, 204, 0) 0%, #2496cc 100%),
);

$dark: (
  primary-lighten2: #483aed,
  primary-lighten1: #483aed,
  primary: #483aed,
  primary-darken1: #483aed,
  primary-darken2: #483aed,
  secondary-lighten2: #6a1b9a,
  secondary-lighten1: #6a1b9a,
  secondary: #6a1b9a,
  secondary-darken1: #6a1b9a,
  secondary-darken2: #6a1b9a,
  success-lighten2: #25e416,
  success-lighten1: #25e416,
  success: #25e416,
  success-darken1: #25e416,
  success-darken2: #25e416,
  warning-lighten2: #f4bf00,
  warning-lighten1: #f4bf00,
  warning: #f4bf00,
  warning-darken1: #f4bf00,
  warning-darken2: #f4bf00,
  error-lighten1: #f42c00,
  error-lighten2: #f42c00,
  error: #f42c00,
  error-darken1: #f42c00,
  error-darken2: #f42c00,
  background: #2d2f45,
  surface: #000000,
  text: #ffffff,
  disabled: #cccccc,
  transparent: transparent,
  diamond: radial-gradient(50% 50% at 50% 50%, #483aed 0%, #3aa2ed 100%),
);

$default: ();

$attributes: (
  text: "color",
  bg: "background",
  border: "border-color",
  fill: "fill",
);

//light
// ex.: text-primary-lighten1-light
@each $type, $attribute in $attributes {
  @each $color, $value in $light {
    .#{$type}-#{$color}-light {
      #{$attribute}: $value;
    }
  }
}

//dark
// ex.: text-primary-lighten1-dark
@each $type, $attribute in $attributes {
  @each $color, $value in $dark {
    .#{$type}-#{$color}-dark {
      #{$attribute}: $value;
    }
  }
}

@if $dark-theme {
  $default: $dark;
} @else {
  $default: $light;
}

//default
// ex.: text-primary-lighten1

@each $type, $attribute in $attributes {
  @each $color, $value in $default {
    .#{$type}-#{$color} {
      #{$attribute}: $value;
    }
  }
}

.diamond-color {
  background: map-get($light, diamond);
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

$linearOpacitySpace: (100px);

.linear-background-light {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #dde2eb $linearOpacitySpace,
    #dde2eb calc(100% - #{$linearOpacitySpace}),
    rgba(221, 226, 235, 0) 100%
  );
}

.white {
  color: #ffffff;
}

.primary-lighten-2 {
  color: #2496cc;
}
