$levels: (
  l-m1: -1,
  l-m2: -2,
  l-m3: -3,
  l-m4: -4,
  l-m5: -5,
  l-0: 0,
  l-1: 1,
  l-2: 2,
  l-3: 3,
  l-4: 4,
  l-5: 5,
);

//ex.: depth-l-0
@each $name, $value in $levels {
  .depth-#{$name} {
    z-index: $value;
  }
}
