.details-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.details-card {
  display: flex;
  padding-top: 60px;
  width: 100%;
  gap: 56px;
}

.details-card-title {
  font-size: 2rem !important;
}

.details-card-subtitle {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 14px;
}

.details-card-captions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.details-card-button {
  font-size: 1rem;
  color: #fff;
  padding: 19px !important;
  background-color: #363636 !important;
  font-weight: 500;
  line-height: 17px;
}

.details-card-button:hover {
  background-color: #363636;
  opacity: 0.9;
}

.details-card-image {
  width: 448px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
