$elevation-none: "none";

$elevation-small: drop-shadow(163px 91px 75px rgba(0, 0, 0, 0.01))
  drop-shadow(92px 51px 63px rgba(0, 0, 0, 0.05))
  drop-shadow(41px 23px 47px rgba(0, 0, 0, 0.09))
  drop-shadow(10px 6px 26px rgba(0, 0, 0, 0.1))
  drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));

$elevation-normal: drop-shadow(151px 85px 48px rgba(0, 0, 0, 0.01))
  drop-shadow(96px 54px 44px rgba(0, 0, 0, 0.05))
  drop-shadow(54px 30px 37px rgba(0, 0, 0, 0.16))
  drop-shadow(24px 14px 28px rgba(0, 0, 0, 0.27))
  drop-shadow(6px 3px 15px rgba(0, 0, 0, 0.32))
  drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.32));

$elevation-large: drop-shadow(202px 103px 63px rgba(0, 0, 0, 0.01))
  drop-shadow(129px 66px 58px rgba(0, 0, 0, 0.06))
  drop-shadow(73px 37px 49px rgba(0, 0, 0, 0.21))
  drop-shadow(32px 17px 36px rgba(0, 0, 0, 0.35))
  drop-shadow(8px 4px 20px rgba(0, 0, 0, 0.4))
  drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.41));

$elevation-huge: drop-shadow(255px 228px 96px rgba(0, 0, 0, 0.01))
  drop-shadow(163px 146px 88px rgba(0, 0, 0, 0.05))
  drop-shadow(92px 82px 74px rgba(0, 0, 0, 0.18))
  drop-shadow(41px 37px 55px rgba(0, 0, 0, 0.31))
  drop-shadow(10px 9px 30px rgba(0, 0, 0, 0.36))
  drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.37));

.elevation-normal {
  box-shadow: 0 0 50px #000000;
}
