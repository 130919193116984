$screen-xs-min: 0px;
$screen-sm-min: 600px;
$screen-md-min: 900px;
$screen-lg-min: 1200px;
$screen-xl-min: 1536px;

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
