.compare {
  margin: 88px auto 0;
  height: 100%;
  min-height: 80vh;
  display: grid;
  align-items: space-between;

  &__main {
    width: 100%;
    max-width: 1200px;
    padding: 0 24px;
    display: grid;
    row-gap: 32px;
    margin: 0 auto 32px;
  }

  &__primaryText {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #4d4e4e;
  }

  &__secondaryText {
    @include cabin;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4d4e4e;
  }

  &__headerText {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    letter-spacing: -0.025em;
    color: #4d4e4e;
  }

  &__header {
    display: grid;
    row-gap: 8px;
  }

  &__desktop {
    display: grid;
    grid-template-columns: 1fr 0px;
    overflow-y: scroll;
  }

  &__row {
    display: grid;
    row-gap: 16px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &--main {
      background: rgba(79, 79, 79, 0.1);
      padding: 16px;
      display: grid;
      gap: 50px;
    }

    &--item {
      display: grid;
      row-gap: 24px;
      align-items: center;
      text-align: center;
      grid-template-rows: repeat(3, minmax(33.33px, 1fr));

      h5 {
        @extend .compare__secondaryText;
      }
    }

    &:last-child {
      border-radius: 0px 0px 10px 10px;
    }
  }

  &__droner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
      border-radius: 10px;
      cursor: pointer;
    }

    .body-3 {
      cursor: pointer;
    }

    .body-2 {
      @include cabin;
      color: #4d4e4e;
      text-align: center;
      max-width: 169px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__addBox {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 169px;
    background: #2496cc;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    cursor: pointer;
    margin: 0 auto;
  }

  &__leftColumn {
    display: grid;
    row-gap: 24px;
    grid-template-rows: repeat(3, minmax(33.33px, 1fr));

    &--item {
      display: flex;
      gap: 10px;
      align-items: center;
      align-self: start;
    }
  }

  // REFERENCES
  &__reference {
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 24px;
  }

  &__images {
    display: grid;
    row-gap: 16px;

    span {
      border-radius: 10px;
      justify-self: center;
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    max-width: 162px;
    margin: 0 auto;
    column-gap: 20px;
  }

  &__remove {
    justify-self: center;
    align-self: flex-end;
    width: 87px;
    height: 37px;
    background-color: unset;
    border: 1px solid #4d4e4e;
    border-radius: 40px;

    color: #4d4e4e;
    @include cabin;
    font-weight: 600;
    font-size: 16px;
    color: #4d4e4e;
  }

  //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// MOBILE DESIGN ////////////////////////////////////
  &__mobile {
    display: grid;
    row-gap: 42px;

    &--addButton {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      height: 40px;
      border-radius: 40px;
      width: max-content;
    }

    &--top {
    }

    &--droners {
      // .compare__droner {
      //   span {
      //     height: 130px !important;
      //     width: 130px !important;
      //   }
      // }
    }

    &--main {
      display: grid;
      row-gap: 16px;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
