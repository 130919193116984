.settings {
  &__text {
    // The reason why I use '!important' is that material ui overrides my styles.
    font-size: 40px !important;
    line-height: 52px !important;
  }

  &__profileTextBox {
    display: flex;
    justify-content: center;

    @media (max-width: 664px) {
      justify-content: flex-start;
    }
  }

  &__profileText {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.025em;
    text-decoration-line: underline;
    color: #4d4e4e;
    cursor: pointer;
    margin: 16px 0 0;

    @media (max-width: 664px) {
      margin: 8px 0 0;
    }
  }

  &__tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
    margin: 0 auto;
    margin-top: 33px;

    @media (max-width: 664px) {
      display: none;
    }
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 36px;
    gap: 10px;
    width: 288px;
    height: 151px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;

    &--primaryText {
      font-weight: 600;
      font-size: 24px;
      line-height: 25px;
      letter-spacing: -0.025em;
      color: #4d4e4e;
    }

    &--secondaryText {
      @include cabin;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #b5b5b5;
    }
  }

  &__tabsMobile {
    display: grid;
    row-gap: 27.5px;
    margin: 28.5px 0 26px;

    @media (min-width: 665px) {
      display: none;
    }
  }

  &__tabMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 26px;
    border-bottom: 1px solid #b5b5b5;
    column-gap: 50px;

    &--main {
      display: grid;
      row-gap: 8px;
    }

    span {
      cursor: pointer;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;

    div {
      display: flex;
      gap: 9.5px;
      align-items: center;
    }

    &--primaryText {
      @include cabin;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #b5b5b5;
    }
  }

  &__loadingDesktop {
    @media (max-width: 664px) {
      display: none;
    }
  }

  &__loadingMobile {
    @media (min-width: 665px) {
      display: none;
    }
  }
}

.settings__underLineText {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.025em;
  text-decoration-line: underline;
  color: #4d4e4e;
  cursor: pointer;
}

.settings__primaryText {
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: #4d4e4e;
  margin: 0;
  text-align: left;
}

.settings__secondaryText {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.025em;
  color: #4d4e4e;
}

.settings__tertiaryText {
  @include cabin;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #b5b5b5;
}

.profileImageTitle {
  @include cabin;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4d4e4e;
}

.settings__inputCover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 26px !important;
  column-gap: 28px;
}

.settingsInput {
  display: grid;
  row-gap: 8px;

  // div {
  //   height: unset !important;
  // }

  &__label {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 8px;
  }

  &__wrapper {
    position: relative;

    &--text {
      position: absolute;
      @include cabin;
      font-weight: 400;
      font-size: 16px;
      color: #b5b5b5;
      top: 15px;
      right: 20px;
      height: min-content;
    }

    &--helper {
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;
    }
  }

  &__input {
    width: 100%;

    div {
      height: unset;
      padding: 0;
    }

    input {
      width: 100%;
      height: 50px;
      border: 1px solid #b5b5b5;
      border-radius: 10px;
      padding: 10px;
      font-size: 15px;
      outline: none;
      box-sizing: border-box;

      // THESE REMOVE THE UP AND DOWN ICON FROM INPUT
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    textarea {
      width: 100%;
      border: 1px solid rgb(181, 181, 181);
      border-radius: 10px;
      padding: 10px;
      font-size: 15px;
      box-sizing: border-box;

      &:first-child {
        min-height: 250px;

        @media (max-width: $screen-sm-min) {
          min-height: 150px;
        }
      }
    }

    fieldset {
      border-color: transparent;
      height: 100%;
      top: 0;

      legend {
        display: none;
      }
    }
  }

  &__errText {
    @include montserrat;
    color: #de4c3f;
    font-weight: 400;
    font-size: 0.8571428571428571rem;
    line-height: 1.66;
    text-align: left;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    margin-top: -5px;
  }
}

.muiCustomizedInput {
  .MuiInputBase-root {
    min-height: 50px !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  fieldset {
    legend {
      display: none;
    }

    border: 1px solid #b5b5b5;
    top: 0;
  }
}

.customizedMuiPlaceInput {
  .css-r5cron-control {
    border: 1px solid #b5b5b5;
  }

  .css-r5cron-control,
  .css-y495es-control {
    height: 50px;

    svg,
    span {
      display: none;
    }
  }
}

.authSettingsInput {
  display: grid;
  row-gap: 8px;

  input {
    width: 100%;
    height: 50px;
    border: 1px solid #b5b5b5;
    border-radius: 10px;
    padding: 10px;
    font-size: 15px;
    outline: none;
  }
}

.uploadImagesBox {
  width: 100%;
  margin: 26px auto 0;
  display: grid;
  grid-auto-columns: 130px 1fr;

  @media (min-width: 664px) {
    margin: 16px auto 0;
    width: 600px;
  }
}

.authSettingsBox {
  width: 100%;
  margin: 34px auto 18px;
  display: grid;
  row-gap: 26px;

  @media (min-width: 664px) {
    width: 600px;
    margin: 24px auto 24px;
  }
}
