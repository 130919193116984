.drone-banner {
  &__desktop {
    min-height: 690px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &--left {
      padding: 0 40px 0 24px;
      text-align: end;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: linear-gradient(to right bottom, #ffffff 30%, #f2f2f2 60%);

      &-content {
        max-width: 520px;
        display: grid;
        row-gap: 32px;

        button {
          justify-self: right;
        }
      }
    }

    &--right {
      @extend .pl-huge,
        .flex,
        .col,
        .w-full,
        .align-items-center,
        .justify-content-center;

      padding-right: 24px;

      span {
        max-height: 690px;
        width: inherit;
      }
    }
  }

  &__mobile {
    background: linear-gradient(to right bottom, #ffffff 30%, #f2f2f2 60%);
    min-height: 400px;

    &--front {
      padding: 16px;
      height: 100%;
    }

    &--back {
      padding: 16px;
      min-height: 400px;
      display: grid;
      place-items: center;

      &-content {
        display: grid;
        row-gap: 16px;

        h2 {
          font-size: 1.5rem;
          color: #4d4e4e;
        }

        p {
          font-size: 1.2rem;
          font-weight: bold;
          color: #828282;
        }

        img {
          height: 200px;
          width: 100%;
        }
      }
    }
  }
}

/// DROPDOWN IN BANNER
.dropdown {
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #b5b5b5;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  &__main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__placeholder {
    @include cabin;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #b5b5b5;
  }

  &__options {
    border: 1px solid #b5b5b5;
    border-radius: 0px 0px 10px 10px;
    padding: 0 24.5px;
    position: absolute;
    width: calc(100% + 2px);
    background-color: #ffffff;
    z-index: 10;
    left: -1px;
    top: 38px;
    margin: 0;

    li {
      border-bottom: 1px solid #b5b5b56b;
      padding: 8px 0;
      list-style: none;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      h6 {
        @include cabin;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #4d4e4e;
      }
    }
  }
}

.dropdown-open {
  border-bottom: 1px solid transparent;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
