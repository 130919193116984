// !!!! Whenever I use "!important" in this page, it is only overriding default styles of the mui components.

/// INSPIRATIONS PAGE
.inspire {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @media (max-width: 599px) {
    row-gap: 32px;
  }

  &__info {
    background-color: #1c1c1c;

    &--content {
      max-width: 1248px !important;
      display: grid !important;
      grid-template-rows: max-content 1fr max-content;
      padding-top: 88px;
      padding-bottom: 16px;

      @media (max-width: 900px) {
        padding-bottom: 24px;
      }

      @media (max-width: 599px) {
        padding-top: 74px;
      }
    }

    &--items {
      display: grid;
      margin-top: 30px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, minmax(min-content, max-content));
      column-gap: 4rem;
      row-gap: 2rem;

      @media (max-width: 900px) {
        display: flex !important;
        flex-direction: column;
        row-gap: 2rem;
      }
    }

    &--item {
      &-header {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @media (max-width: 900px) {
          flex-direction: row;
        }

        span {
          width: max-content !important;
        }

        h2 {
          font-size: 1.8rem;
          margin: 0 !important;
          height: 2rem;

          @media (max-width: 900px) {
            height: unset;
          }
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        .inspire__info--item-header {
          h2 {
            height: unset;
          }
        }
      }

      &-text {
        font-size: 1rem !important;
        margin-top: 20px !important;
        @media (max-width: 900px) {
          margin-top: 11px !important;
        }
      }
    }

    &--bottom {
      text-align: center;

      span {
        cursor: pointer;
      }

      @media (max-width: 900px) {
        display: none;
      }
    }
  }

  &__categories {
    max-width: 1248px !important;

    // display: flex;flex-direction: column ;

    .subtitle-1 {
      font-weight: 700;
      display: none;
      color: #4d4e4e;

      @media (max-width: 599px) {
        display: block;
        margin-bottom: 16px;
      }
    }

    &--items {
      display: flex;
      justify-content: space-between;
      overflow-x: scroll;
      column-gap: 16px;

      svg {
        font-size: 72px;
      }

      @media (max-width: 599px) {
        svg {
          height: 28px;
          width: 28px;
        }
      }
    }

    &--item {
      text-align: center;
      cursor: pointer;
      @media (max-width: 599px) {
        svg {
          color: #4d4e4e;
        }
      }

      &-active {
        svg,
        p {
          color: #2496cc !important;
        }
      }

      &:hover {
        svg,
        p {
          color: #2496cc !important;
        }
      }
    }
  }

  &__main {
    display: flex !important;
    flex-direction: column !important;
    row-gap: 16px;
    max-width: 1248px !important;

    @media (min-width: 600px) {
      row-gap: 32px;
    }

    &--header {
      h2 {
        margin: 0 0 16px 0;
      }
    }

    &--allItems {
      display: grid;
      row-gap: 15px;
    }
  }

  &__item {
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    &--image {
      //height: 100%;
      //width: 100%;

      span {
        height: 100% !important;
        width: 100% !important;
      }
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__loadMore {
    padding: 15px 0 !important;
    font-size: 18px !important;
  }

  &__communities {
    max-width: 1248px !important;
    display: grid !important;
    row-gap: 32px;

    @media (min-width: 600px) {
      margin: 24px auto;
      row-gap: 64px;
    }
  }
}

/// INPIRATIONS SECTION IN HOMEPAGE
%space {
  margin-right: 1vw;

  @media (max-width: 599px) {
    margin: 0;
  }
}

.inspire-section {
  display: grid;
  grid-template-columns: repeat(19, minmax(30px, 1fr));
  grid-template-rows: repeat(193, 5px);
  margin-top: 24px;

  @media (max-width: 599px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 200px);
    gap: 10px;
  }

  & > * {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .xs {
    @extend %space;
    background-size: cover;
    background-position: center;

    &-1 {
      grid-column: 8 / span 3;
      grid-row: 58 / span 44;

      @media (max-width: 599px) {
        grid-column: 2 / 3;
        grid-row: 9 / 10;
      }
    }

    &-2 {
      grid-column: 1 / span 3;
      grid-row: 105 / span 43;

      @media (max-width: 599px) {
        grid-column: 1 / 2;
        grid-row: 10 / 11;
      }
    }

    &-3 {
      grid-column: 1 / span 3;
      grid-row: 151 / -1;

      @media (max-width: 599px) {
        grid-column: 2 / 3;
        grid-row: 10 / 11;
      }
    }
  }

  .s {
    @extend %space;
    grid-row: 1 / span 54;
    background-size: cover;
    background-position: center;

    &-1 {
      grid-column: 8 / span 4;

      @media (max-width: 599px) {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
      }
    }

    &-2 {
      grid-column: 12 / span 4;

      @media (max-width: 599px) {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
      }
    }

    &-3 {
      grid-column: 16 / span 4;
      margin-right: 0 !important;

      @media (max-width: 599px) {
        grid-column: 1 / 2;
        grid-row: 9 / 10;
      }
    }
  }

  .m {
    @extend %space;
    background-size: cover;
    background-position: center;

    &-1 {
      grid-column: 1 / span 7;
      grid-row: 1 / span 101;

      @media (max-width: 599px) {
        grid-column: 1 / -1;
        grid-row: 1 / span 2;
      }
    }

    &-2 {
      grid-column: 4 / span 7;
      grid-row: 105 / -1;

      @media (max-width: 599px) {
        grid-column: 1 / -1;
        grid-row: 4 / span 2;
      }
    }
  }

  .l {
    grid-column: 11 / -1;
    grid-row: 58 / -1;
    background-size: cover;
    background-position: center;

    @media (max-width: 599px) {
      grid-column: 1 / -1;
      grid-row: 6 / span 3;
    }
  }

  &__item {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    /// HOVER EFFECT
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: none;
      z-index: 1;
    }

    &:hover {
      &::after {
        display: block;
      }

      .hover {
        display: flex;
      }
    }

    /// ITEM SHOWN ON HOVER
    .hover {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 16px;
      z-index: 10;
      padding: 0 10px;
      text-align: center;
      width: 100%;

      &__rating,
      &__type {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
      }

      &__rating {
        display: flex;
        column-gap: 8px;
        padding: 8px;

        img {
          height: 14px;
          width: 24px;
        }
      }

      &__type {
        padding: 8px;

        h5 {
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
          color: #ffffff;
        }
      }

      &__bottom {
        display: flex;
        column-gap: 12px;
        align-items: center;

        div {
          background: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
          display: grid;
          place-items: center;
          cursor: pointer;
        }

        div:first-child {
          height: 32px;
          width: 32px;

          svg {
            margin-top: -2px;
            height: 18px;
            width: 12.8px;
          }
        }

        div:last-child {
          height: 26.5px;
          width: 26.5px;

          svg {
            height: 16px;
            width: 12.8px;
          }
        }
      }
    }
  }
}
