button {
  @extend .pointer;
  @extend .button;
  @extend .flex;
  @extend .align-items-center;
  @extend .gap-normal;
  @extend .justify-content-center;
  @extend .px-large;
  @extend .py-normal;
  border-width: 0;
  @extend .rounded-all-huge;
  @extend .text-primary-darken1;
  @extend .bg-primary-lighten2;
  text-transform: none;
  letter-spacing: 0;

  &[disabled] {
    @extend .cursor-default;
    @extend .bg-disabled;
    @extend .text-text;
  }

  &[data-variant="white-contained"] {
    @extend .bg-surface;
    @extend .text-primary-lighten2;
    padding: 20px 25px;

    &:hover {
      opacity: 80%;
      transition: 0.3s;
    }
  }

  &[data-variant="large-outlined"] {
    @extend .bg-transparent;
    @extend .text-surface;
    @extend .border-surface-tiny;
    padding: 20px 25px;

    &:hover {
      @extend .bg-surface;
      @extend .text-primary-lighten2;
      transition: 0.3s;
    }
  }

  &[data-variant="large-text-outlined"] {
    @extend .bg-transparent;
    @extend .text-text;
    @extend .border-text-tiny;
    padding: 20px 25px;

    &:hover {
      @extend .bg-primary-lighten2;
      @extend .border-primary-lighten2-tiny;
      @extend .text-surface;
      transition: 0.3s;
    }
  }

  &[data-variant="large-angular-outlined"] {
    @extend .bg-transparent;
    @extend .text-text;
    @extend .border-text-tiny;
    @extend .rounded-all-small;
    height: 57px;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 600;
    padding: 40px 45px;

    &:hover {
      @extend .bg-secondary;
      @extend .border-secondary-tiny;
      @extend .text-surface;
      transition: 0.1s;
    }
  }

  &[data-variant="small-outlined"] {
    @extend .text-text;
    @extend .bg-transparent;
    @extend .border-text-lighten2-tiny;
    @extend .p-normal;

    &:hover {
      @extend .text-primary-lighten2;
      @extend .border-primary-lighten2-tiny;
    }
  }

  &[data-variant="large-error"] {
    @extend .bg-error;
    @extend .text-surface;
    padding: 15px 20px;
    @extend .rounded-all-small;

    &:hover {
      @extend .bg-error-lighten1;
      transition: 0.3s;
    }
  }

  &[data-variant="link"] {
    @extend .caption;
    @extend .bg-transparent;
    @extend .p-none;
    text-decoration: underline;
  }

  &[data-variant="text"] {
    @extend .bg-transparent;
    @extend .p-none;
    text-transform: none;
  }

  &[data-variant="primary-light"] {
    @extend .bg-primary-lighten2;
    @extend .text-surface;
    text-transform: none;

    &:hover {
      @extend .bg-primary-darken1;
      transition: 0.3s;
    }
  }

  &[data-variant="login"] {
    @extend .bg-primary-lighten2;
    @extend .text-surface;
    @extend .rounded-all-normal;
    padding: 15px 29px;
    font-weight: 600;

    &:hover {
      @extend .bg-primary-darken1;
      transition: 0.3s;
    }
  }

  &[data-color="primary"] {
    @extend .bg-primary-lighten2;

    &:hover {
      transition: 0.2s;
      @extend .bg-primary;
    }
  }

  &[data-color="secondary"] {
    @extend .text-secondary-lighten1;
    @extend .bg-secondary-lighten2;
  }

  &[data-color="text"] {
    @extend .text-text;
  }
}
