.webinar {
  background: linear-gradient(90deg, #ffffff 0%, #ffffffb3 98.17%);
  min-height: 300px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    width: 100%;
    @media (max-width: 599px) {
      align-items: center;
      text-align: center;
      width: 100%;
      row-gap: 16px;
    }

    h1 {
      color: #4d4e4e;
    }

    h2 {
      font-weight: 600;
      color: #4d4e4e;
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 134.4%;
      letter-spacing: -0.025em;
      color: #828282;
    }
  }
}

.large-banner-background {
  top: calc(100vh + 1427px);
}

.small-banner-background {
  top: calc(100vh + 527px);
}

.potential-droners {
  height: 900px;
  background-image: url(../../../public/assets/potential-droners-background.webp);
  display: grid;
  place-items: center;

  &__main {
    background: #ffffff33;
    backdrop-filter: blur(27px);
    width: 100%;
    max-width: 1609px;
    padding: 50px 0;
    min-height: 359px;
  }

  &__content {
    max-width: 1248px !important;
    display: grid !important;
    row-gap: 16px;

    h2,
    p {
      color: #ffffff;
    }
  }

  .benefits {
    display: grid;
    row-gap: 24px;

    &__item {
      display: flex;
      column-gap: 10px;
      align-items: center;
      color: white;
    }
  }
}

.pricingBanner {
  background: rgba(79, 79, 79, 0.05);
  padding: 48px 0;

  &__header {
    color: #4d4e4e;
    text-align: center;
    display: grid;
    row-gap: 20px;
    margin-bottom: 32px;

    h2 {
      font-size: 32px;
    }

    h5 {
      font-weight: 700;
    }
  }

  .swiper-slide {
    width: 272px !important;
  }

  &__item {
    padding: 16px;
    display: grid;
    align-content: space-between;
    background-color: #ffffff;
    height: 318px;
    width: 272px;

    &--name {
      font-size: 28px;
      height: 56px;
    }

    h6 {
      color: #2496cc;
      font-size: 14px;
      line-height: 16px;
    }

    p {
      @include cabin;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4d4e4e;
    }

    &__section {
      display: grid;
      row-gap: 16px;
    }

    &--divider {
      width: 30px;
      height: 1px;
      background-color: #b5b5b5;
      // margin-top: 20px;
    }

    &--button {
      padding: 0 25px;
      border: 1px solid #2496cc;
      height: 50px;
      border-radius: 100px;
      background-color: unset;
      justify-self: flex-start;
      @include cabin;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: #2496cc;
    }

    &--comingSoon {
      height: 50px;
      display: grid;
      align-items: center;

      h4 {
        font-size: 28px;
        color: #f4bf00;
      }
    }
  }
}

.scrollX__items {
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(5, 272px);
  grid-auto-columns: 272px;
  grid-auto-flow: column;
  column-gap: 10px;
}

.droner-card {
  position: relative;

  &:hover {
    .droner-card-popup {
      height: 100%;
      transition: 0.5s all;

      &-subtitle {
        margin-top: 7%;
      }

      &-btns {
        width: 50%;

        .switch-btn {
          display: inline-block;
        }
      }

      &-description {
        display: block;
      }

      &-text {
        font-weight: 400;
        margin-top: 14px;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;

        span {
          font-weight: 700;
        }
      }

      &-block {
        margin: 14px 0 10px 0;
      }

      &-profile {
        display: flex;
        justify-content: flex-end;

        & > div {
          display: flex;
          align-items: center;
        }

        &-title {
          @extend .droner-card-popup-title;
          display: inline-block;
          margin-right: 16px;
        }

        &-arrow {
          display: inline-block;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  &-popup {
    position: absolute;
    transition: 0.5s all;
    width: 100%;
    height: 26%;
    bottom: 0;
    right: 0;
    padding: 19px 19px 16px 16px;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 8px;
    justify-content: space-between;

    &-btns {
      display: flex;
      width: 63px;
      justify-content: space-between;

      .switch-btn {
        display: none;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      text-transform: uppercase;
      color: #ffffff;
    }

    &-subtitle {
      @extend .droner-card-popup-title;
      font-weight: 700;
      letter-spacing: -0.025em;
      margin-top: 1.5%;
    }

    &-description {
      display: none;
    }
  }
}

.flex-column {
  flex-direction: column;
}

.MuiPopover-paper:has(> div > .filter_popup) {
  margin-top: 29px;
  width: 480px;
}

.MuiPopover-paper:has(> .filter_popup) {
  margin-top: 29px;
  width: 265px;
  margin-left: 34px;
  max-height: 410px;

  background: #ffffff !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 40px !important;
}

.filter_popup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  padding: 26px;

  &_search {
    width: 100%;
  }

  &_location {
    min-height: 200px;
    height: 283px;
  }

  &_subtitle {
    font-family: "Cabin";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #b5b5b5;
  }

  input {
    width: 100%;
    border: 1px solid #b5b5b5;
    border-radius: 40px;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 11px;
    letter-spacing: -0.025em;

    color: #b5b5b5;
  }

  &_list {
    width: 100%;
    padding-bottom: 8px;
    height: auto;
    max-height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;

    &_wrap {
      width: 100%;
      padding-bottom: 8px;

      &_first {
        border-bottom: 1px solid #2496cc;
      }
    }

    &_first {
      max-height: 73px !important;
    }

    &_item {
      width: 100%;
      // margin-left: 5px !important; // to show cyrcle on hover
      //margin-top: 8px;
      min-height: 20px;
    }

    /* width */
    &::-webkit-scrollbar {
      display: block !important;
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      width: 3px;
      height: 71px;

      background: #b5b5b547;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      width: 3px;
      height: 30px;
      background: #b5b5b5;
      border-radius: 40px;
    }
  }
}
