.job-title-link {
  @extend .text-primary;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    @extend .text-primary-darken2;
  }
}

.title-disabled {
  @extend .text-disabled;
  text-decoration: none;
  font-weight: 600;
}

.link-hover {
  &:hover {
    @extend .text-primary-lighten2;
  }
}

.nav-item-hover {
  @extend .text-text;

  &:hover,
  :active {
    transition: 0.1s;
    @extend .text-primary-lighten2;
  }
}

.nav-item-active {
  @extend .text-primary-lighten2;
  @extend .p-small;
  @extend .rounded-all-large;
  @extend .border-primary-lighten2-tiny;
}

.share-link {
  @extend .bg-transparent;
  @extend .text-text;
  text-decoration: underline;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.025em;

  &:hover {
    transition: 0.1s;
    text-decoration: none;
  }
}

.back-link {
  @extend .text-text;
  text-decoration: underline;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.025em;
  width: fit-content;

  &:hover {
    transition: 0.1s;
    text-decoration: none;
  }
}
