.skills {
  overflow-x: scroll;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.skill {
  background: #fff;
  border-radius: 100px;
  padding: 1px 10px;
  margin: 10px;
  margin-left: 0;
  cursor: pointer;
}
