.screen-card {
  @extend .bg-surface;
  @extend .rounded-all-large;
  @extend .px-normal;
  @extend .py-large;
}

.card-hover {
  &:hover {
    transition: 0.2s ease-in-out;
    @extend .card-shadow;
  }
}

.card-icon-big {
  min-width: 65px;
  min-height: 65px;
}

.card-icon-small {
  min-height: 50px;
  min-width: 50px;
}

.job-card {
  height: 200px;
}
