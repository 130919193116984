@import "colors";
@import "spacing";

@each $colorName, $colorValue in $light {
  @each $spaceName, $spaceValue in $spaces {
    .border-#{$colorName}-#{$spaceName} {
      border: #{$spaceValue} solid #{$colorValue};
    }
  }
}
