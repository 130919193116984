@import "./screenSizes";

@mixin cabin {
  font-family: "Cabin", sans-serif;
}

@mixin montserrat {
  font-family: "Montserrat", sans-serif;
}

@mixin base-text {
  @extend .p-none;
  @extend .m-none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.no-decoration {
  text-decoration: none;
}

.headline-1,
h1 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.025em;
}

.headline-2,
h2 {
  @include base-text;
  @include montserrat;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 38px;
  letter-spacing: -0.025em;
}

.headline-3,
h3 {
  @include base-text;
  @include montserrat;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.025em;
}

.headline-4,
h4 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.025em;
}

.subtitle-1,
h5 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 159.9%;
  letter-spacing: -0.025em;
}

.subtitle-2,
h6 {
  @include base-text;
  @include cabin;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 134.4%;
  letter-spacing: -0.025em;
}

.subtitle-3 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.025em;
}

.subtitle-4 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.025rem;
  line-height: 25px;
  letter-spacing: -0.025em;
}

.subtitle-5 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.025em;
}

.subtitle-6 {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.025em;
}

.body-1 {
  @include base-text;
  @include cabin;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.025em;
}

.body-2,
p,
span {
  @include base-text;
  @include montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.025em;
}

.body-3 {
  @include base-text;
  @extend .text-text;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -2.5%;
}

.button {
  @include base-text;
  @include cabin;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.05em;
}

.caption {
  @include base-text;
  @include cabin;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.025em;
}

.overline {
  @include base-text;
  @include cabin;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.015em;
}

.line-through {
  text-decoration: line-through;
}

.underline-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.text-no-wrap {
  white-space: nowrap;
}

.skill-tag {
  @extend .bg-primary-lighten2;
  padding: 3px 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  opacity: 0.7;
  border-radius: 10px;
  color: #ffffff;
  @include cabin;

  @media (max-width: 599px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
  }
}

.discount-tag {
  @extend .border-surface-tiny;
  @extend .px-normal;
  @extend .py-small;
  @extend .rounded-all-normal;
  @extend .text-surface;
  width: fit-content;
  font-weight: 600;
  font-size: 0.625em;
}

.blue-small-text {
  @include montserrat;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: #2496cc;
  @media (max-width: 599px) {
    font-size: 24px;
    line-height: 29px;
  }
}

.blue-big-text {
  @include montserrat;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.025em;
  color: #2496cc;

  @media (max-width: 599px) {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }
}
