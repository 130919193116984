@import "theme/colors";
@import "theme/depth";
@import "theme/effects";
@import "theme/elevation";
@import "theme/layout";
@import "theme/rounding";
@import "theme/screenSizes";
@import "theme/text";
@import "theme/display";
@import "theme/position";
@import "theme/fonts";
@import "utility/buttons";
@import "utility/drawer";
@import "theme/spacing";
@import "utility/links";
@import "utility/cards";
@import "theme/borders";
@import "components/footer";
@import "components/inspirations";
@import "components/drone-banner";
@import "components/project";
@import "pages/settings";
@import "pages/compare";
@import "pages/chat";
@import "pages/register";
@import "pages/login";
@import "pages/pricing";
@import "pages/details";
@import "pages/index";
@import "pages/inspirations";
@import "pages/discounts";
@import "pages/profile";
@import "components/skills";
@import "components/calendar";

$primary-color: #f7931e;
$secondary-color: #4f4f4f;
$primary-color: #f7931e;
$background-color: #ececec;
$paper-color: white;

html {
  height: 100%;
  font-size: 14px;
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

#__next {
  height: 100%;
}

html * {
  font-family: Montserrat, sans-serif;
}

body {
  height: 100%;
  font-family: Montserrat;
  width: 100%;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.background {
  background-color: $background-color;
}

.suggestion-item--active {
  background-color: #c9c9c9;
  border-radius: 5px;
  padding: 0 1px;
}

@mixin social-button {
  @extend .rounded-all-normal;
  @extend .text-text;
  @extend .border-text-tiny;
  @extend .bg-transparent;
  line-height: 1.5rem;
  padding: 15px 25px;
  font-weight: 600;
  position: relative;
  &:hover {
    transition: 0.3s;
    background-color: #f7f7f7;
  }

  &:before {
    content: "";
    position: absolute;
    padding: 12px;
    left: 1em;
    height: 1em;
    width: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.google-button {
  @include social-button;

  &:before {
    background: url("/assets/googleIcon.svg") no-repeat;
    background-size: 100% 100%;
  }
}

.facebook-button {
  @include social-button;

  &:before {
    background: url("/assets/facebookIcon.svg") no-repeat;
    background-size: 100% 100%;
  }
}

.apple-button {
  @include social-button;
  overflow: visible;

  &:before {
    background-image: url("/assets/appleIcon.svg");
    background-size: 100% 100%;
  }
}

.twitter-button {
  @include social-button;

  &:before {
    background: url("/assets/twitterIcon.svg") no-repeat center;
    background-size: 100% 100%;
  }
}

.yahoo-button {
  @include social-button;

  &:before {
    background: url("/assets/yahooIcon.svg") no-repeat center;
    background-size: 100% 100%;
  }
}

select {
  border-radius: 6px;
  padding: 4px 8px;
  margin: 12px 0;
}

.glass-card {
  background: transparent;
  backdrop-filter: blur(20px);
}

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-height: 100vh;
}

.card-shadow {
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.25);
}

.drop-shadow {
  -webkit-filter: drop-shadow(5px 5px 10px #000);
  filter: drop-shadow(5px 5px 10px #000);
}

.chip-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}

.contained-input {
  width: 100%;
  border-radius: 40px;
  outline: gray 2px;
  padding: 8px 16px 8px 34px;
  border: none;
  background-color: #fff;
}

.inline {
  display: inline-block;
}

.hidden {
  display: none;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.scroll-contain {
  overscroll-behavior-y: contain;
}

.touch-none {
  touch-action: none;
}

.hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.center-absolute-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center-absolute-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-horizontal {
  left: 50%;
  transform: translateX(-50%);
}

.center-vertical {
  top: 50%;
  transform: translateY(-50%);
}

.no-highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.max-width-full {
  max-width: 100%;
}

a {
  text-decoration: none;
}
