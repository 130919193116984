@import "../theme/screenSizes";
@import "../theme/spacing";

//size
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.h-auto {
  height: auto;
}

.fit-content {
  height: fit-content;
  width: fit-content;
}

//position
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

//container
.container {
  @extend .px-large;
  @include sm {
    padding-right: map-get($spaces, huge);
    padding-left: map-get($spaces, huge);
  }
  @include lg {
    margin-left: auto;
    margin-right: auto;
    width: $screen-lg-min;
  }
  @include xl {
    width: $screen-xl-min;
  }
}

.inline-block {
  display: inline-block;
}

//flex
.flex {
  display: flex;
}

$flexDirections: (
  row: row,
  row-rev: row-reverse,
  col: column,
  col-rev: column-reverse,
);
@each $dir, $value in $flexDirections {
  .#{$dir} {
    flex-direction: $value;
  }
}

$flexContent: (
  start,
  center,
  end,
  space-between,
  space-around,
  space-evenly,
  stretch
);

@each $cont in $flexContent {
  .justify-content-#{$cont} {
    justify-content: $cont;
  }
  .align-content-#{$cont} {
    align-content: $cont;
  }
}

$flexItems: (start, center, end, stretch, space-between, space-around);
@each $cont in $flexItems {
  .justify-items-#{$cont} {
    justify-items: $cont;
  }
  .align-items-#{$cont} {
    align-items: $cont;
  }
}

@each $cont in $flexItems {
  .justify-self-#{$cont} {
    justify-self: $cont;
  }
  .align-self-#{$cont} {
    align-self: $cont;
  }
}

$wrapValues: (nowrap, wrap, reverse);
@each $value in $wrapValues {
  .flex-#{$value} {
    flex-wrap: $value;
  }
}

//text
$textAlignment: (left, center, right);
@each $alignment in $textAlignment {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

$overflowOptions: (scroll, hidden, auto, visible);

$overflowAttributes: (
  all: overflow,
  x: overflow-x,
  y: overflow-y,
);

@each $type, $attr in $overflowAttributes {
  @each $value in $overflowOptions {
    .overflow-#{$type}-#{$value} {
      #{$attr}: $value;
    }
  }
}

$testColors: (red, blue, green, cyan, lightblue);

$testWidth: (1, 2, 4, 6, 8, 10, 20);

@each $color in $testColors {
  @each $width in $testWidth {
    .test-border-#{$color}-#{$width} {
      border: #{$width}px solid #{$color};
    }
  }
}

.spacer {
  flex-grow: 1;
}
