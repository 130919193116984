$rounded-none: 0px;
$rounded-small: 4px;
$rounded-normal: 8px;
$rounded-large: 16px;
$rounded-huge: 32px;

$radius: (
  none: 0px,
  small: 4px,
  normal: 8px,
  large: 16px,
  huge: 32px,
  full: 50%,
);

$attributes: (
  all: "border-radius",
  top: "border-top-radius",
  bottom: "border-bottom-radius",
  tl: "border-top-left-radius",
  tr: "border-top-right-radius",
  br: "border-bottom-right-radius",
  bl: "border-bottom-left-radius",
);

//ex.: rounded-all-none
@each $type, $attribute in $attributes {
  .rounded-#{$type} {
    @each $item, $radius in $radius {
      &-#{$item} {
        #{$attribute}: $radius;
      }
    }
  }
}
