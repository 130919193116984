$spaces: (
  none: 0px,
  tiny: 1px,
  small: 4px,
  normal: 8px,
  large: 16px,
  huge: 32px,
  extra: 64px,
  m-none: -0px,
  m-tiny: -2px,
  m-small: -4px,
  m-normal: -8px,
  m-large: -16px,
  m-huge: -32px,
  m-extra: -64px,
  auto: auto,
);

$attributes: (
  p: padding,
  pt: padding-top,
  pb: padding-bottom,
  pl: padding-left,
  pr: padding-right,
  m: margin,
  mt: margin-top,
  mb: margin-bottom,
  ml: margin-left,
  mr: margin-right,
  top: top,
  bottom: bottom,
  left: left,
  right: right,
);

$flexAttr: (
  gap: gap,
  row-gap: row-gap,
  column-gap: column-gap,
  grod-gap: grid-gap,
  grid-row-gap: grid-row-gap,
  grid-column-gap: grid-column-gap,
  border-all: border-width,
  border-top: border-top-width,
  border-bottom: border-bottom-width,
  border-left: border-left-width,
  border-right: border-right-width,
);

//ex.: m-none
@each $type, $attribute in $attributes {
  @each $space, $value in $spaces {
    .#{$type}-#{$space} {
      #{$attribute}: $value;
    }
  }
}

//ex.: py-huge
@each $space, $value in $spaces {
  .py-#{$space} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }
  .px-#{$space} {
    padding-left: #{$value};
    padding-right: #{$value};
  }
  .my-#{$space} {
    margin-top: #{$value};
    margin-bottom: #{$value};
  }
  .mx-#{$space} {
    margin-left: #{$value};
    margin-right: #{$value};
  }
}

@each $type, $attribute in $flexAttr {
  @each $space, $value in $spaces {
    .#{$type}-#{$space} {
      #{$attribute}: $value;
    }
  }
}
