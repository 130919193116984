.login {
  &__header {
    display: grid;
    grid-template-columns: max-content 1fr;
    text-align: center;
    align-items: center;
    padding: 24px 28px;

    h4 {
      margin-left: -16px;
    }

    span {
      cursor: pointer;
    }
  }

  &__section {
    display: grid;
    padding: 16px 28px;
    row-gap: 16px;
  }

  &__checkbox {
    padding: 0 !important;

    svg {
      width: 15px;
      height: 15px;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  &__smallText {
    padding: 0 !important;
    margin-left: unset !important;
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: max-content;

    span {
      @include cabin;
      font-weight: 400 !important;
      line-height: 20px !important;
      color: #4d4e4e !important;
    }
  }

  &__terms {
    display: grid;
    row-gap: 4px;

    .caption {
      a {
        text-decoration-line: underline;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  &__middle {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
}
