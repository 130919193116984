$displays: (
  inline,
  block,
  contents,
  flex,
  grid,
  inline-block,
  inline-flex,
  inline-grid,
  inline-table,
  list-item,
  run-in,
  table,
  table-caption,
  table-column-group,
  table-header-group,
  table-footer-group,
  table-row-group,
  table-cell,
  table-column,
  table-row,
  none,
  initial,
  inherit
);

@each $display in $displays {
  .d-#{$display} {
    display: $display;
  }
}
