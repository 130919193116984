.discounts {
  display: grid;
  row-gap: 45px;

  @media (max-width: 599px) {
    row-gap: 26px;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #1c1c1c;
    height: 50vh;
    margin-top: 66px;
    position: relative;

    @media (max-width: 599px) {
      height: auto;
      display: unset;
      margin-top: 50px;
      padding: 22px 16px;
    }

    &--left {
      position: absolute;
      max-width: 1248px !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: 599px) {
        position: unset;
        transform: unset;
        h4 {
          margin-bottom: 16px;
        }
      }

      &-content {
        max-width: 367px;
      }

      h1,
      h4 {
        color: #2496cc;
      }
    }

    &--right {
      // background-image: url("../../../public//assets/discounts_cover_img.webp");
      background-size: 100% 100%;
      grid-column: 2/3;

      @media (max-width: 599px) {
        display: none;
      }
    }
  }

  &__info {
    max-width: 1248px !important;

    h3 {
      color: #2496cc;

      @media (max-width: 599px) {
        font-size: 16px;
      }
    }

    &--divider {
      height: 2px;
      background: #b5b5b5;
      margin-top: 8px;
    }
  }

  &__container {
    max-width: 1248px !important;
    display: grid !important;
    row-gap: 44px;
  }

  &__row {
    display: grid;
    row-gap: 24px;

    &--items {
      display: grid;
      column-gap: 8px;
      row-gap: 15px;
      overflow-x: scroll;
    }
  }

  &__searchedItems {
    max-width: 1248px !important;
    display: flex !important;
    column-gap: 8px;
    row-gap: 20px;
    flex-wrap: wrap;
  }

  .no-discounts {
    margin: 0 auto;
  }

  &__bottom {
    max-width: 1248px !important;
    display: grid !important;
    row-gap: 64px;
    margin: 19px 0;
  }
}

.discount-item {
  &__discount {
    background: #f9a11b;
    border-radius: 50%;
    display: grid;
    place-items: center;

    p {
      font-weight: 600;
    }
  }
}

/// SEARCH BOX
.search-box {
  max-width: 1248px !important;
  display: grid !important;
  row-gap: 32px;
  justify-content: center;

  @media (max-width: 599px) {
    display: block !important;
  }

  &_mobile {
    &__top {
      display: flex !important;
      flex-direction: column;
      height: auto !important;
      border-radius: 0 !important;
      background: #0000 !important;
      box-shadow: none !important;
    }

    &__categories {
      &-item {
        display: block;
        align-items: center;
        min-height: 61px;
        padding: 0 16px 40px 16px;
        padding-top: 0;
        width: 100%;
        column-gap: 16px;
        background-color: #ffff;

        .search-box__input {
          box-shadow: none;
          border: 1px solid #b5b5b5;
        }

        &-header {
          border-bottom: 2px solid #b5b5b5;
          padding-bottom: 16px;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .subtitle-3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 159.9%;
          letter-spacing: -0.025em;
          color: #4d4e4e;
        }

        .subtitle-4 {
          //styleName: body-1;
          font-family: "Cabin";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #b5b5b5;
        }

        .filter_popup_location {
          min-height: 120px;
          max-height: 168px;
        }

        .filter_popup {
          padding: 0;

          &_range {
            padding: 0;
            padding-top: 20px;
          }

          &_subtitle {
            display: none;

            &-mobile {
              display: block;
              margin: 0;
              line-height: 0;
            }
          }

          &_list {
            max-height: 168px !important;

            &_wrap {
              max-height: 168px !important;

              &_first {
                max-height: 168px !important;
              }
            }

            &_first {
              max-height: 168px !important;
            }
          }
        }
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 0 8px;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 4px 14px #00000040;
    border-radius: 90px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  &__categories {
    display: flex;
    align-items: center;
    height: 40px;
    column-gap: 16px;

    .active-item {
      margin: 0;
      height: 40px;
      background: #ffffff;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      border-radius: 90px;
      padding: 0 8px;
      display: grid;
      place-items: center;
    }

    hr {
      height: 30px;
      margin-top: 5px;
    }

    &-item {
      cursor: pointer;
      position: relative;

      .subtitle-3 {
        color: #4d4e4e;
      }
    }
  }

  &__input {
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: 8px 8px 8px 16px;
    gap: 16px;
    width: 300px;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 90px;
    margin: 0 auto;

    @media (max-width: 599px) {
      width: 100%;
    }

    input {
      outline: none;
      border: none;
    }
  }

  &__iconBox {
    width: 40px;
    height: 40px;
    background: #2496cc;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;

    svg {
      color: #ffffff;
    }
  }
}
