.project-popup {
  display: grid;
  grid-template-rows: max-content 1fr;

  &__header {
    height: 60px;
    border-bottom: 1px solid #b5b5b5;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    padding: 0 24px;

    svg {
      z-index: 5;
    }

    h5 {
      font-weight: 700;
      text-align: center;
      color: #4d4e4e;
      margin-left: -24px;
    }
  }

  &__main {
    padding: 24px;
    display: grid;
    row-gap: 24px;

    h3 {
      text-align: center;
    }
  }

  &__items {
    display: grid;
    row-gap: 18px;
  }

  &__item {
    display: grid;
    row-gap: 10px;

    h5 {
      font-weight: 700;
    }

    .body-1 {
      font-size: 16px;
    }

    &--ratings {
      display: flex;
      align-items: center;
      column-gap: 5px;

      h6 {
        font-weight: 600;
        font-size: 16px;
        color: #4d4e4e;
      }
    }
  }
}

.project-carousel {
  min-height: 300px;

  & > div {
    text-align: left;
  }

  button {
    margin: 14px 10px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__smallImage {
    width: 76px;
    height: 66px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
}

// PROJECT CARD CONTENT
.project-card-content {
  height: 48%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: flex-end;

  .padding-small {
    padding: 0 10px 11px 8px;
    width: 100%;
  }

  .padding-large {
    padding: 0 24px 24px;
  }

  &__items {
    display: grid;
    row-gap: 10px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    align-items: center;

    .subtitle-3 {
      color: #ffffff;
    }

    svg {
      width: 10px;
      height: 13px;
    }
  }

  &__icon {
    height: 26px;
    width: 26px;
    background-color: rgba(256, 256, 256, 0.5);
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s all;

    &:hover {
      background-color: rgba(256, 256, 256, 1);
      transition: 0.5s all;
    }
  }

  &__box {
    background: #ffffff46;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    width: max-content;

    h6 {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
    }

    svg {
      height: 11px;
      width: 11px;
      color: #ffffff;
    }
  }
}
