.register {
  &__header {
    .blue-big-text {
      color: #4d4e4e;
      margin-bottom: 8px;
    }
  }

  &__backLogin {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 32px;
    position: relative;

    @media (max-width: $screen-sm-min) {
      display: grid;
      grid-template-columns: max-content 1fr;
      text-align: center;
      padding-bottom: 26px;
      // border-bottom: 1px solid #b5b5b5;
      margin-bottom: 26px;

      &::after {
        content: "";
        position: absolute;
        border: 1px solid #b5b5b5;
        bottom: 0;
        left: -24px;
        width: 648px;
      }
    }
  }
}

.stepper {
  width: 100%;
  max-width: 599px;
  margin: 36px auto 20px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 16px;

  @media (max-width: $screen-sm-min) {
    margin: 26px auto 20px;
  }

  .active {
    background: #2496cc;
    border: unset;

    .overline {
      color: #ffffff;
    }
  }

  &__item {
    display: flex;
    gap: 16px;
    align-items: center;

    @media (max-width: $screen-sm-min) {
      display: grid;
      width: 85px;
      align-items: center;
      justify-content: center;
      text-align: center;
      div {
        margin: 0 auto;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #4d4e4e;

      .overline {
        color: #4d4e4e;
      }
    }
  }

  &__line {
    border: 1px solid #b5b5b5;

    @media (max-width: $screen-sm-min) {
      align-self: flex-start;
      margin-top: 11.5px;
    }
  }
}

.certification-input {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 24px;
}
